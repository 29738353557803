<template>
  <!--begin::Service Listing-->
  <div class="level_2-template">
    <PageHeaderCount
      moduleType="adlevel2"
      :dataLoading.sync="dataLoading"
      :statusList.sync="statusList"
      allkey="all_level2s"
      countkey="level2_status_count"
    ></PageHeaderCount>
    <ListingTemplate
      :customClass="'level_2-listing'"
      v-if="getPermission('level_2:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-col cols="4" class="pt-0">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('level_2:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    left
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in bulkActions">
                        <template v-if="item.action == 'delete'">
                          <v-list-item
                            link
                            v-on:click="serviceDeleteDialog = true"
                            :key="index"
                          >
                            <v-list-item-icon
                              class="margin-auto-zero mr-3 my-2"
                            >
                              <v-icon class="icon-default-blue">{{
                                item.icon
                              }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title
                              class="font-weight-500 font-size-14"
                              >{{ item.title }}</v-list-item-title
                            >
                          </v-list-item>
                        </template>
                        <template v-else>
                          <v-list-item
                            link
                            v-on:click="productBulkAction(item.status)"
                            :key="index"
                          >
                            <v-list-item-icon
                              class="margin-auto-zero mr-3 my-2"
                            >
                              <v-icon class="icon-default-blue">{{
                                item.icon
                              }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title
                              class="font-weight-500 font-size-14"
                              >{{ item.title }}</v-list-item-title
                            >
                          </v-list-item>
                        </template>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="4" class="pt-0">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_level2s
                          }}</template>
                          <template v-else>{{
                            item.level2_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('level_2:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="openCreateDialog()"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  v-if="false"
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  v-if="false"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'service',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <template v-if="false">
                  <v-menu content-class="custom-menu-list" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading || exportLoading"
                        class="mx-2 custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                        :loading="exportLoading"
                      >
                        <v-icon dark>mdi-menu</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in moreActions">
                        <v-list-item
                          :key="index"
                          link
                          :class="{ 'line-active': item.sort }"
                          v-on:click="moreAction(item.action, item.sort)"
                        >
                          <v-list-item-title>
                            <v-icon v-if="item.icon" small left color="cyan">{{
                              item.icon
                            }}</v-icon>
                            {{ item.title }}
                            <template v-if="item.sort">
                              <v-icon
                                v-if="item.sort == 'asc'"
                                small
                                right
                                color="cyan"
                                >mdi-sort-ascending</v-icon
                              >
                              <v-icon
                                v-if="item.sort == 'desc'"
                                small
                                right
                                color="cyan"
                                >mdi-sort-descending</v-icon
                              >
                            </template>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="cols.fixed || dataLoading"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <!-- <PageTips module="service"></PageTips> -->
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Service"
          :basicSearchFields="['name', 'barcode', 'reference']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="position: static"
        >
          <ListingTable
            :columnCount.sync="defaultColShow.length"
            :dataLoading.sync="dataLoading"
            :rowData.sync="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in defaultColDefs">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{
                        'checkbox-width-limit dbx-checkbox': cols.checkbox,
                        'dbx-barcode': cols.field == 'barcode',
                        'dbx-added-at': cols.field == 'added_at',
                        'dbx-updated-at': cols.field == 'updated_at',
                      }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template v-if="cols.checkbox">
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData.length > 0">
                  <tr
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    :class="{ 'table-in-active-row': !data.activated }"
                  >
                    <template v-for="cols in defaultColDefs">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'dbx-checkbox': cols.checkbox,
                          'dbx-barcode': cols.field == 'barcode',
                          'dbx-added-at': cols.field == 'added_at',
                          'dbx-updated-at': cols.field == 'updated_at',
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        v-on:click="detailRow(cols.checkbox, data)"
                        :style="{ 'max-width': cols.width }"
                      >
                        <template v-if="cols.checkbox">
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <Barcode :barcode="data.barcode"></Barcode>
                        </template>
                        <template v-else-if="cols.field == 'adlevel2_info'">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Name: </b> {{ data.name || " - " }}
                          </p>
                          <p
                            class="m-0 custom-nowrap-ellipsis"
                            v-if="data.description"
                          >
                            <b>Description: </b> {{ data.description || " - " }}
                          </p>
                          <p
                            class="m-0 custom-nowrap-ellipsis"
                            v-if="data.uom_text"
                          >
                            <b>Unit: </b> {{ data.uom_text || " - " }}
                          </p>
                          <p
                            class="m-0 custom-nowrap-ellipsis"
                            v-if="data.reference"
                          >
                            <b>Reference: </b> {{ data.reference || " - " }}
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'price'">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Supplier Cost: </b>
                            <template v-if="data.company_cost">{{
                              formatMoney(data.company_cost)
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Supplier Cost</em
                              ></template
                            >
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Mark Up: </b>
                            <template v-if="data.markup">{{
                              formatMoney(data.markup)
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Mark Up</em
                              ></template
                            >
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Price: </b>
                            <template v-if="data.charges">{{
                              formatMoney(data.charges)
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Price</em
                              ></template
                            >
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data.sync="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data.sync="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="defaultColDefs.length" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no level 2 at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading.sync="dataLoading"
          :showingFrom.sync="showingFrom"
          :showingTo.sync="showingTo"
          :totalRows.sync="totalRows"
          :currentPage.sync="currentPage"
          :totalPages.sync="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Additional Work Title</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="position: static"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFilterLabel(field, 'Additional Work Title')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <template v-if="getPermission('adlevel2:create')">
          <Dialog :commonDialog="importDialog">
            <template v-slot:title>Import Additional Work Title</template>
            <template v-slot:body>
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="position: static"
              >
                <v-container fluid class="pt-0 custom-search-filter">
                  <v-row>
                    <template>
                      <a href="./../Sample/services.xlsx" download>
                        <v-btn
                          :disabled="dataLoading"
                          class="mx-2 custom-grey-border custom-bold-button"
                          color="cyan white--text"
                        >
                          Download Sample
                        </v-btn></a
                      >
                    </template>
                  </v-row>
                  <v-row>
                    <template>
                      <div class="container">
                        <form enctype="multipart/form-data" method="POST">
                          <div class="large-4 medium-4 small-4 cell">
                            <label>
                              <input
                                type="file"
                                id="file"
                                ref="file"
                                v-on:change="handleFileUpload()"
                              />
                            </label>
                          </div>
                        </form>
                      </div>
                    </template>
                  </v-row>
                </v-container>
              </perfect-scrollbar>
            </template>
            <template v-slot:action>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="importRows"
              >
                Import
              </v-btn>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="importDialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
        </template>

        <template v-if="getPermission('level_2:create')">
          <Dialog
            :common-dialog="additional_dialog"
            :dialog-width="dialogWidth"
          >
            <template v-slot:title>
              <span v-if="adlevel2Create.id">Update Level 2</span>
              <span v-else>Create Level 2</span>
            </template>
            <template v-slot:body>
              <v-layout class="align-center">
                <v-flex md2>
                  <label class="font-weight-700 font-size-16 width-100 required"
                    >Level 2</label
                  >
                </v-flex>
                <v-flex md10>
                  <v-text-field
                    dense
                    filled
                    label="Enter Level 2"
                    solo
                    flat
                    hide-details
                    color="cyan"
                    v-model.trim="adlevel2Create.name"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex md2 class="pt-5">
                  <label class="font-weight-700 font-size-16 width-100 mt-2"
                    >Level 2 Description</label
                  >
                </v-flex>
                <v-flex md10 class="pt-5">
                  <v-textarea
                    dense
                    filled
                    label="Enter Level 2 Description"
                    solo
                    flat
                    hide-details
                    color="cyan"
                    v-model.trim="adlevel2Create.description"
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <label class="my-0 pt-6 font-weight-700 font-size-18"
                >Other Details</label
              >
              <hr />
              <v-row>
                <v-col md="6">
                  <label class="font-weight-700 font-size-16 width-100 mt-2"
                    >Unit</label
                  >
                  <v-autocomplete
                    dense
                    filled
                    color="cyan"
                    item-color="cyan"
                    :items="unitList"
                    label="Select Unit"
                    solo
                    hide-details
                    flat
                    append-outer-icon="mdi-cog"
                    v-on:click:append-outer="manageUnitDialog = true"
                    item-text="text"
                    item-value="id"
                    class="width-100"
                    v-model.trim="adlevel2Create.unit"
                    v-on:change="updateUnit()"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Unit(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col md="6">
                  <label class="font-weight-700 font-size-16 width-100 mt-2"
                    >Supplier Cost</label
                  >
                  <v-text-field
                    dense
                    filled
                    color="cyan"
                    label="Enter Supplier Cost"
                    solo
                    flat
                    hide-details
                    class="pl-2 width-100"
                    type="number"
                    min="0"
                    max="9999"
                    prepend-inner-icon="mdi-currency-usd"
                    v-model.trim="adlevel2Create.company_cost"
                    v-on:keypress="
                      limitDecimal($event, adlevel2Create.company_cost)
                    "
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <label class="font-weight-700 font-size-16 width-100 mt-2"
                    >Mark Up</label
                  >
                  <v-text-field
                    dense
                    filled
                    color="cyan"
                    label="Enter Mark Up"
                    solo
                    hide-details
                    flat
                    class="width-100"
                    type="number"
                    min="0"
                    max="9999"
                    v-on:keypress="limitDecimal($event, adlevel2Create.markup)"
                    prepend-inner-icon="mdi-percent"
                    v-model.trim="adlevel2Create.markup"
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <label class="font-weight-700 font-size-16 width-100 mt-2"
                    >Price</label
                  >
                  <v-text-field
                    dense
                    filled
                    color="cyan"
                    label="Enter Price"
                    solo
                    flat
                    hide-details
                    class="pl-2 width-100"
                    type="number"
                    min="0"
                    max="9999"
                    prepend-inner-icon="mdi-currency-usd"
                    v-model.trim="adlevel2Create.charges"
                    v-on:keypress="limitDecimal($event, adlevel2Create.charges)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-slot:action>
              <v-btn
                :loading="additional_loading"
                :disabled="additional_loading"
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="saveAdditionalTitle()"
              >
                Save
              </v-btn>
              <v-btn
                :disabled="additional_loading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="closeCreateDialog()"
              >
                Cancel
              </v-btn>
            </template>
          </Dialog>
        </template>
        <template v-if="getPermission('level_2:view')">
          <Dialog
            :common-dialog="adw_detail_dialog"
            :dialog-width="dialogWidth"
          >
            <template v-slot:title>
              <v-layout>
                <v-flex> Level 2 </v-flex>
                <v-flex class="text-right">
                  <v-btn
                    :disabled="updateLoading"
                    :loading="updateLoading"
                    class="mx-2 custom-bold-button"
                    :color="
                      adlevel2Detail.activated
                        ? 'red lighten-1 white--text'
                        : 'cyan white--text'
                    "
                    v-on:click="updateStatus()"
                    >Mark as
                    {{
                      adlevel2Detail.activated ? "Inactive" : "Active"
                    }}</v-btn
                  >
                  <v-btn
                    :disabled="updateLoading"
                    :loading="updateLoading"
                    class="mx-2 custom-bold-button"
                    color="cyan white--text"
                    v-on:click="updateRow()"
                    >Edit</v-btn
                  >
                  <v-btn
                    :disabled="updateLoading"
                    :loading="updateLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="
                      adlevel2Detail = {};
                      adw_detail_dialog = false;
                    "
                    >Close</v-btn
                  >
                </v-flex>
              </v-layout>
            </template>
            <template v-slot:body>
              <div>
                <v-tabs
                  v-model="adlevel2Tab"
                  background-color="transparent"
                  color="cyan"
                  class="custom-tab-transparent"
                >
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    href="#overview"
                  >
                    Overview
                  </v-tab>
                  <v-tab
                    v-if="false"
                    class="font-size-16 font-weight-600 px-8"
                    href="#additional-work"
                  >
                    Additional Works DB
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="adlevel2Tab">
                  <v-tab-item value="overview">
                    <div class="px-2 my-4">
                      <table
                        width="100%"
                        style="table-layout: fixed; color: #263035"
                      >
                        <tr>
                          <td
                            class="font-size-18 font-weight-500 py-2"
                            width="20%"
                          >
                            Name
                          </td>
                          <td
                            class="font-weight-600 font-size-18 py-2"
                            width="80%"
                          >
                            {{ adlevel2Detail.name }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="font-size-18 font-weight-500 py-2 pt-4"
                            width="20%"
                            valign="top"
                          >
                            Description
                          </td>
                          <td
                            class="font-weight-600 font-size-18 py-2"
                            width="80%"
                          >
                            <div style="white-space: pre-line">
                              <template v-if="dataLoading">
                                <v-skeleton-loader
                                  class="custom-skeleton width-80"
                                  type="text"
                                >
                                </v-skeleton-loader>
                              </template>
                              <template v-else>
                                <read-more
                                  class="custom-read-more font-weight-600 font-size-18 pb-1"
                                  more-str="read more"
                                  :text="
                                    adlevel2Detail.description ||
                                    '<em>No Description</em>'
                                  "
                                  link="#"
                                  less-str="read less"
                                  :max-chars="400"
                                >
                                </read-more>
                              </template>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2" class="custom-border-bottom">
                            <label
                              class="my-0 pt-6 pb-2 font-weight-700 font-size-18"
                              >Other Details</label
                            >
                          </td>
                        </tr>
                      </table>
                      <table
                        width="100%"
                        style="table-layout: fixed; color: #263035"
                      >
                        <tr>
                          <td class="font-size-18 font-weight-500">
                            <table width="100%">
                              <tr>
                                <td
                                  class="font-size-18 font-weight-500 py-2 pt-4"
                                  width="40%"
                                  valign="top"
                                >
                                  Unit
                                </td>
                                <td
                                  class="font-weight-600 font-size-18 py-2"
                                  width="60%"
                                >
                                  {{ adlevel2Detail.uom_text }}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="font-size-18 font-weight-500 py-2 pt-4"
                                  width="40%"
                                  valign="top"
                                >
                                  Markup
                                </td>
                                <td
                                  class="font-weight-600 font-size-18 py-2"
                                  width="60%"
                                >
                                  {{ formatMoney(adlevel2Detail.markup) }}
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td class="font-size-18 font-weight-500">
                            <table width="100%">
                              <tr>
                                <td
                                  class="font-size-18 font-weight-500 py-2 pt-4"
                                  width="40%"
                                  valign="top"
                                >
                                  Supplier Cost
                                </td>
                                <td
                                  class="font-weight-600 font-size-18 py-2"
                                  width="60%"
                                >
                                  {{ formatMoney(adlevel2Detail.company_cost) }}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="font-size-18 font-weight-500 py-2 pt-4"
                                  width="40%"
                                  valign="top"
                                >
                                  Price
                                </td>
                                <td
                                  class="font-weight-600 font-size-18 py-2"
                                  width="60%"
                                >
                                  {{ formatMoney(adlevel2Detail.charges) }}
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </v-tab-item>
                  <v-tab-item value="additional-work">
                    <div class="px-2 my-4">
                      <div class="v-data-table simple-table table-height">
                        <div class="v-data-table__wrapper">
                          <table
                            width="100%"
                            style="table-layout: fixed; color: #263035"
                          >
                            <thead>
                              <tr>
                                <th class="simple-table-th">
                                  Additional Work #
                                </th>
                                <th class="simple-table-th">
                                  Additional Work's Info
                                </th>
                                <th class="simple-table-th">Price</th>
                                <th class="simple-table-th">Created Time</th>
                              </tr>
                            </thead>
                            <tbody
                              class="custom-border-bottom"
                              v-if="adlevel2Detail.adw_db_relation"
                            >
                              <tr
                                v-for="(
                                  data, index
                                ) in adlevel2Detail.adw_db_relation"
                                :key="`ad-row-${index}`"
                              >
                                <td class="simple-table-td">
                                  <Barcode
                                    route="service.detail"
                                    :id="data.id"
                                    :barcode="data.barcode"
                                  ></Barcode>
                                </td>
                                <td class="simple-table-td">
                                  <p class="m-0 custom-nowrap-ellipsis">
                                    <b>Name: </b> {{ data.name || " - " }}
                                  </p>
                                </td>
                                <td class="simple-table-td">
                                  <p class="m-0 custom-nowrap-ellipsis">
                                    <b>Price: </b>
                                    <template v-if="data.charges">{{
                                      formatMoney(data.charges)
                                    }}</template>
                                    <template v-else
                                      ><em class="text--secondary"
                                        >No Price</em
                                      ></template
                                    >
                                  </p>
                                  <p class="m-0 custom-nowrap-ellipsis">
                                    <b>Supplier Cost: </b>
                                    <template v-if="data.company_cost">{{
                                      formatMoney(data.company_cost)
                                    }}</template>
                                    <template v-else
                                      ><em class="text--secondary"
                                        >No Supplier Cost</em
                                      ></template
                                    >
                                  </p>
                                </td>
                                <td class="simple-table-td">
                                  <TableActivity
                                    v-if="!lodash.isEmpty(data.added_by)"
                                    :data.sync="data"
                                  >
                                    <template v-slot:display_name>
                                      {{ data.added_by.display_name }}
                                    </template>
                                    <template v-slot:date_time>
                                      {{ data.created_at }}
                                    </template>
                                    <template v-slot:format_date_time>
                                      {{ formatDateTime(data.added_at) }}
                                    </template>
                                  </TableActivity>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </template>
          </Dialog>
        </template>
      </template>
    </ListingTemplate>
    <ManageUnit
      :dialog.sync="manageUnitDialog"
      :unit.sync="unitList"
      v-on:close-dialog="manageUnitDialog = false"
      v-on:get-product-unit="getOptions"
    ></ManageUnit>
    <ServiceDelete
      :deleteDialog.sync="serviceDeleteDialog"
      :requestUrl.sync="serviceDeleteRequestUrl"
      :dataRequest="{ products: selectedRows }"
      v-on:delete:success="serviceDeleteSuccess"
      v-on:delete:close="serviceDeleteDialog = false"
    ></ServiceDelete>
  </div>
  <!--end::Service Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import ServiceMixin from "@/core/lib/service/service.mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ManageUnit from "@/view/pages/partials/Manage-Product-Unit.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import {
  DOWNLOAD,
  QUERY,
  UPDATE_ERROR,
  POST,
  PUT,
  PATCH,
  GET,
} from "@/core/services/store/request.module";

import { saveAs } from "file-saver";
//import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import ServiceDelete from "@/view/pages/partials/Bulk-Delete-Request-Template.vue";
import { SearchEventBus } from "@/core/lib/search.lib";

export default {
  mixins: [CommonMixin, ListingMixin, ServiceMixin],
  name: "level_2-list",
  data() {
    return {
      exportLoading: false,
      manageUnitDialog: false,
      additional_loading: false,
      additional_dialog: false,
      adw_detail_dialog: false,
      unitList: [],
      pageModule: "level_2-listing",
      routeAPI: "adlevel2",
      routeName: "level_2",
      routeDetailName: "level_2.detail",
      status: "all",
      file: "",
      importDialog: false,
      serviceDeleteDialog: false,
      serviceDeleteRequestUrl: "adlevel2",
      statusList: [],
      allowedSearchFields: [
        "barcode",
        "company_name",
        "person_display_name",
        "unit_no",
        "street_1",
        "street_2",
        "zip_code",
        "city_name",
        "state_name",
        "country_name",
      ],
      adlevel2Tab: "overview",
      adlevel2Create: {
        id: null,
        name: null,
        description: null,
        product_type: "level2",
        taxable: false,
        unit: null,
        uom_id: 0,
        uom_value: null,
        uom_text: null,
        company_cost: 0,
        markup: 0,
        charges: 0,
      },
      updateLoading: false,
      adlevel2Detail: {},
      moreActions: [
        {
          title: "Import Additional Work Title(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },
        {
          title: "Export Additional Work Title(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export"
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          status: 1,
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          status: 0,
          action: "inactive",
        },
        /*{
          title: "Delete",
          icon: "mdi-check-all",
          action: "delete",
        },*/
      ],
    };
  },
  components: {
    Barcode,
    TableActivity,
    draggable,
    PageHeaderCount,
    //PageTips,
    ManageUnit,
    ListingTemplate,
    ListingFooter,
    ServiceDelete,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
  },
  watch: {
    "adlevel2Create.company_cost"() {
      let company_cost =
        this.lodash.toNumber(
          (this.lodash.toNumber(this.adlevel2Create.company_cost) *
            this.lodash.toNumber(this.adlevel2Create.markup)) /
            100
        ) + this.lodash.toNumber(this.adlevel2Create.company_cost);
      this.adlevel2Create.charges = company_cost.toFixed(2);
    },
    "adlevel2Create.markup"() {
      let company_cost =
        this.lodash.toNumber(
          (this.lodash.toNumber(this.adlevel2Create.company_cost) *
            this.lodash.toNumber(this.adlevel2Create.markup)) /
            100
        ) + this.lodash.toNumber(this.adlevel2Create.company_cost);
      this.adlevel2Create.charges = company_cost.toFixed(2);
    },
  },
  methods: {
    updateUnit() {
      let productUOM = this.lodash.find(this.unitList, {
        id: this.adlevel2Create.unit,
      });
      this.adlevel2Create.uom_id = productUOM ? productUOM.id : null;
      this.adlevel2Create.uom_value = productUOM ? productUOM.value : null;
      this.adlevel2Create.uom_text = productUOM ? productUOM.text : null;
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "product/options",
          data: { product_type: "level2" },
        })
        .then((response) => {
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.uomlist
          ) {
            _this.unitList = response.data.uomlist;
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
    closeCreateDialog() {
      this.additional_dialog = false;
      if (this.adlevel2Create?.id) {
        this.adw_detail_dialog = true;
      }
    },
    updateStatus() {
      this.updateLoading = true;
      this.$store
        .dispatch(PUT, { url: `adlevel2/${this.adlevel2Detail.id}/status` })
        .then(() => {
          this.detailRow(false, { id: this.adlevel2Detail.id });
          this.getRows();
        })
        .catch((error) => {
          this.logError(error);
          this.updateLoading = false;
        });
    },
    detailRow(is_checkbox, row) {
      if (is_checkbox) {
        return false;
      }
      this.adlevel2Tab = "overview";
      this.$store
        .dispatch(GET, { url: `adlevel2/${row.id}` })
        .then(({ data }) => {
          this.adlevel2Detail = data;
          this.adw_detail_dialog = true;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.updateLoading = false;
        });
    },
    updateRow() {
      this.adw_detail_dialog = false;
      this.adlevel2Create = {
        id: this.adlevel2Detail.id,
        name: this.adlevel2Detail.name,
        description: this.adlevel2Detail.description,
        unit: this.adlevel2Detail.uom_id,
        company_cost: this.adlevel2Detail.company_cost,
        markup: this.adlevel2Detail.markup,
        charges: this.adlevel2Detail.charges,
        uom_id: this.adlevel2Detail.uom_id,
        uom_value: this.adlevel2Detail.uom_value,
        uom_text: this.adlevel2Detail.uom_text,
        product_type: "level2",
        taxable: false,
      };
      this.$nextTick(() => {
        this.additional_dialog = true;
      });
    },
    openCreateDialog() {
      let uom = this.lodash.head(this.unitList);
      if (uom && uom?.id) {
        this.adlevel2Create.unit = uom.id;
        this.adlevel2Create.uom_id = uom.id;
        this.adlevel2Create.uom_value = uom.value;
        this.adlevel2Create.uom_text = uom.text;
      }
      this.$nextTick(() => {
        this.additional_dialog = true;
      });
    },
    saveAdditionalTitle() {
      const _this = this;

      if (!_this.adlevel2Create.name) {
        return false;
      }

      let requestType = POST;
      let requestURL = "adlevel2";

      if (_this.adlevel2Create.id) {
        requestType = PUT;
        requestURL = "adlevel2/" + _this.adlevel2Create.id;
      }

      _this.adlevel2Create.product_type = "level2";

      _this.additional_loading = true;

      _this.$store
        .dispatch(requestType, { url: requestURL, data: _this.adlevel2Create })
        .then((output) => {
          _this.additional_dialog = false;
          _this.adw_detail_dialog = true;
          _this.adlevel2Detail = {};
          _this.detailRow(false, { ...output.data });
          _this.adlevel2Create = new Object({
            id: null,
            name: null,
            description: null,
            product_type: "adlevel2",
            taxable: false,
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getRows();
          _this.additional_loading = false;
        });
    },
    serviceDeleteSuccess() {
      this.serviceDeleteDialog = false;
      this.getRows();
    },
    productBulkAction(status) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "product",
          data: {
            status,
            products: _this.selectedRows,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkServicesExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("service");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    importRows() {
      // const _this = this;
      const file = this.$refs.file.files[0];
      if (!file) {
        alert("No file chosen");
        return;
      }
      const data = new FormData();
      data.append("excel", file);
      const _this = this;
      _this.$store
        .dispatch(POST, {
          url: "services/import",
          data: data,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
    },
    bulkServicesExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "services/export",
        })
        .then(({ data }) => {
          saveAs(data, "services-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.getOptions();

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Level 2",
      },
    ]);

    SearchEventBus.$emit("search-module-title", "Level 2");

    if (this.getPermission("service:create") == false) {
      let index = this.lodash.findIndex(this.moreActions, {
        action: "import_items",
      });
      if (index >= 0) {
        this.moreActions.splice(index, 1);
      }
    }
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [
      {
        headerName: "",
        field: "id",
        sort: null,
        visible: true,
        fixed: true,
        sortable: false,
        checkbox: true,
        order: 0,
        width: "50px",
      },
      {
        headerName: "Level 2 #",
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 1,
        width: "200px",
      },
      {
        headerName: "Level 2 Title",
        field: "adlevel2_info",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 2,
        width: "200px",
      },
      {
        headerName: "Price",
        field: "price",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 2,
        width: "200px",
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 3,
        width: "170px",
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 4,
        width: "170px",
      },
    ];

    const defaultColDefs = _this.lodash.filter(
      _this.defaultColDefs,
      function (row) {
        return row.visible;
      }
    );

    _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
